import {graphql} from "gatsby";
import * as React from "react";
import Layout from "../components/Layout/layout";
import Seo from "../components/SEO/SEO";
import TestimonialsHero from "../components/Testimonials/Hero";
import TestimonialsList from "../components/Testimonials/List";
import FreeDemoForm from "../components/Form/FreeDemo";

const TestimonialsPage = (props) => {
    const {
        location,
        seo,
        data: {pageData, testimonials, testimonialsLogos},
    } = props;

    const activeProductSlug = location?.state?.activeProductSlug;

    return (
        <Layout>
            <Seo seo={seo} />
            <TestimonialsHero clients={pageData.clients} counters={pageData.counters} />
            <TestimonialsList
                activeProductSlug={activeProductSlug}
                testimonials={testimonials.edges}
                logos={testimonialsLogos}
            />
            <FreeDemoForm />
        </Layout>
    );
};

export const pageQuery = graphql`
    query {
        pageData: strapiTestimonialsPage {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            clients {
                image {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
            }
            counters {
                id
                number
                description
            }
        }
        testimonialsLogos: allStrapiTestimonials {
            edges {
                node {
                    logo {
                        id
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        testimonials: allStrapiTestimonials(sort: {order: DESC, fields: sortOrder}) {
            edges {
                node {
                    strapiId
                    testimonial
                    author
                    case {
                        slug
                    }
                    products {
                        type
                    }
                    logo {
                        id
                    }
                }
            }
        }
    }
`;

export default TestimonialsPage;
